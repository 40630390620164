* {
    box-sizing: border-box;
}

header {
    font-size: 1.1em;
    padding-top: 2em;
    padding-bottom: 2em;
}

.flex-row-baseline {
    display: flex;
    align-items: last baseline;
}

footer {
    font-size: 0.8em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: center;
}

div.footerlinks {
    display: flex;
    align-items: last baseline;
    flex-direction: row;
    gap: 1em;
    justify-content: center;
}

div.badges {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
}

.wrapper {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em;
}

.aspect-ratio {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
  
.aspect-ratio iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
}
  
@supports(padding:max(0px)) {
    .wrapper {
        padding-left: max(1em, env(safe-area-inset-left));
        padding-right: max(1em, env(safe-area-inset-right));
    }
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1;
}

.title {
    font-size: 2em;
    margin-right: auto;
    font-weight: bolder;
}

nav {
    display: flex;
    align-items: last baseline;
    flex-direction: row;
    gap: 1em;
}

p.copyright {
}

// Colors

body {
    color: #000;
    background-color: #fff;
}

main {
}

header {
    color: #000;
}

a {
    color: #1156B4;
}

@media (prefers-color-scheme: dark) {
    body {
        color: #eee;
        background-color: #222;
    }

    main {
    }
    
    header {
        color: #eee;
    }

    a {
        color: #339EE9;
    }    
}
  