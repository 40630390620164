* {
    box-sizing: border-box;
}

header {
    font-size: 1.1em;
    padding-top: 2em;
    padding-bottom: 2em;
}

.flex-row-baseline {
    display: flex;
    align-items: last baseline;
}

footer {
    font-size: 0.8em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.wrapper {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em;
}

@supports(padding:max(0px)) {
    .wrapper {
        padding-left: max(1em, env(safe-area-inset-left));
        padding-right: max(1em, env(safe-area-inset-right));
    }
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1;
}

.title {
    font-size: 2em;
    margin-right: auto;
    font-weight: bolder;
}

nav {
    display: flex;
    align-items: last baseline;
    flex-direction: row;
    gap: 1em;
}

a {
    text-decoration: none;
}

a.current {
    text-decoration: underline;
}

p.copyright {
    text-align: center;
}

// Colors

body {
    color: #000;
    background-color: #fff;
}

main {
    box-shadow: 0 4px 6px -4px #bbb;
}

header {
    color: #000;
    border-bottom: 1px solid #ddd;
}

a {
    color: #000;
}    

@media (prefers-color-scheme: dark) {
    body {
        color: #eee;
        background-color: #222;
    }

    main {
        box-shadow: 0 4px 6px -4px #111;
    }
    
    header {
        color: #eee;
        border-bottom: 1px solid #111;
    }
    
    a {
        color: #eee;
    }    
}
  